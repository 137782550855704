import React, { useState } from "react";
import { Grid, TextField, FormControl, Typography, Button, FormGroup, FormControlLabel, Switch, Snackbar, Alert } from "@mui/material";
import postByApi from '../../api/post'
import { useTranslation } from "react-i18next";

const SettingsScreen = () => {
  const {t} = useTranslation()
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [checkPassword, setCheckPassword] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [alertMsg, setAlertMsg] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const notificationOptions = ["Notify me an hour before class", "Notify me when the class is rescheduled"]
  const user = JSON.parse(sessionStorage.getItem('user'))
  
  const changePassword = (e) => {
    //TODO
    e.preventDefault()
    if(oldPassword === '' || newPassword === '') {
      setIsValid(false)
      setAlertMsg('pw_post_failed')
      setOpenSnackbar(true)
      return
    }
    if(newPassword !== checkPassword) {
      setIsValid(false)
      setAlertMsg('pw_not_match')
      setOpenSnackbar(true)
      return
    }
    postByApi('/api/student/info/password/update', {
      token: user.token,
      originalPassword: oldPassword,
      newPassword
    }).then(r => {
      if(r === undefined) {
        setIsValid(false)
        setAlertMsg('pw_post_failed')
      }
      else {
        setIsValid(true)
        setAlertMsg('pw_post_success')
      }
      setOpenSnackbar(true)
    })
    .catch(err => console.log(err))
  }

  return (
    <div style={{paddingTop: '5vh'}}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">{t('pw')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <form className="inputForm" style={{margin: '2vh 0'}} onSubmit={changePassword}>
            <FormControl>
              <Typography>{t('opw')}</Typography>
              <TextField size="small" value={oldPassword} onChange={e => setOldPassword(e.target.value)} type="password"/>
            </FormControl>
            <FormControl>
              <Typography>{t('npw')}</Typography>
              <TextField size="small" value={newPassword} onChange={e => setNewPassword(e.target.value)} type="password" />
            </FormControl>
            <FormControl>
              <Typography>{t('cpw')}</Typography>
              <TextField size="small" value={checkPassword} onChange={e => setCheckPassword(e.target.value)} type="password" />
            </FormControl>
            <Button type="submit">{t('submit')}</Button>
          </form>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant="h5">Notifications</Typography>
          <FormGroup>
            {notificationOptions.map(option => {
              return <FormControlLabel key={option} control={<Switch defaultChecked /> } label={option} />
            })}
          </FormGroup>
        </Grid> */}
      </Grid>
      <Snackbar 
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={1500}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        sx={{marginTop: '5vh'}}
      >
        <Alert severity={isValid? "success" : "warning"} sx={{alignItems: 'center', display: 'flex'}}>{t(alertMsg)}</Alert>
      </Snackbar>
    </div>
  )
}

export default SettingsScreen;