import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import getFromApi from "../../api/get";
import ListSkeleton from "../../components/ListSkeleton";
import { useTranslation } from "react-i18next";

const CourseHistoryScreen = () => {
  const {t} = useTranslation()
  const [history, setHistory] = useState([])
  const user = JSON.parse(sessionStorage.getItem('user'))
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getFromApi('/api/student/my_course', 
    {
      token: user.token
    })
    .then(r => {
      if(r !== undefined)
        setHistory(r.courses)
      setIsLoading(false)
    })
    .catch(err => console.log(err))
  },[])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4">{t('course_history')}</Typography>
        {isLoading ? <ListSkeleton num={3} width={'80%'} height={'5vh'} /> : 
        history.length > 0 ? history.map(rec => {
          return <Paper className="paperItem" elevation={4} key={rec.id}>
            <Typography variant="h6">{rec.title}</Typography>
            <Typography sx={{display: 'flex', alignItems: 'center', padding: '1.5vh 0'}}><AccessTimeIcon sx={{paddingRight: '0.5vw'}} /> {t('course_len_detail',{count:rec.lessonCount, min: rec.classMinutes })}</Typography>
            <Typography sx={{display: 'flex', alignItems: 'center'}}><PaidOutlinedIcon sx={{paddingRight: '0.5vw'}} />{`CAD${rec.classPrice}`}</Typography>
            <Typography sx={{display: 'flex', alignItems: 'center', padding: '1.5vh 0'}}><BeenhereOutlinedIcon sx={{paddingRight: '0.5vw'}} />{`${rec.completedLessonPercentage*100}%`}</Typography>
          </Paper>
        }) : <div style={{display: 'flex', height: '80vh', alignItems: 'center', justifyContent: 'center'}}>
        <Typography variant="h6">{t('no_class_rec_msg')}</Typography>
      </div>}
      </Grid>
    </Grid>
  )
}

export default CourseHistoryScreen;