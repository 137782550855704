import React from "react";
import { Skeleton } from "@mui/material";

const ListSkeleton = ({num, width='80%', height='5vh'}) => {
  const arrToRepeat = Array.from({length: num})
  return (
    <>
      {arrToRepeat.map((item, index) => (
        <Skeleton key={index} variant="rectangular" animation="wave" width={width} height={height} sx={{margin: '2vh 3vw'}} />
      ))}
    </>
  )
}

export default ListSkeleton