import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: true,
      interpolation: {
        escapeValue: false,
      },
      lng: 'en',
      resources: {
        en: {translation: require('./locales/en/translation.json')},
        ch: {translation: require('./locales/ch/translation.json')}
      }
    })

export default i18next;