import axios from "axios";

const postByApi = async (url, data) => {
  try {
    console.log('posting data',data)
    let res = await axios({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    })
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export default postByApi;