import React, { useEffect, useState } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import getFromApi from '../api/get'
import ListSkeleton from './ListSkeleton'
import { useTranslation } from "react-i18next";

const Notifications = () => {
  const { t } = useTranslation()
  const [noti, setNoti] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const user = JSON.parse(sessionStorage.getItem('user'))
  

  useEffect(() => {
    setIsLoading(true)
    getFromApi('/api/student/class_list',{
      token: user.token,
      limit: 5,
      type: 4
    })
    .then(r => {
      if(r !== undefined) {
        setNoti(r.classes.map(c => {
          return {classTime: new Date(c.startTime).toLocaleString(), teacher: c.teacher.alias}
        }))
      }
      setIsLoading(false)
    })
  },[])

  return (
    <Paper elevation={0} sx={{minWidth: {xs:'30vw', md: 'inherit'}, minHeight: {xs: '30vh', md: 'inherit'}}}>
      <Typography variant="h5">{t('noti')}</Typography>
      {isLoading? <ListSkeleton num={3} width="75%" height="2vh" /> : noti.length > 0 ? noti.map((n, index) => {
        return <Box key={index} sx={{borderRight: '0.4rem solid #a0211c', padding: '1vh 2vw 1vh 0', margin: '1.5vh 0', display: {md: index > 2 ? 'none': 'normal'}}}>
          <p style={{verticalAlign: 'center', wordWrap: 'break-word'}}>{t('cancel_msg', {date: n.classTime, teacher: n.teacher})}</p>
        </Box>
      }): <div style={{display: 'flex', height: '20vh', alignItems: 'center', justifyContent: 'center'}}>
      <Typography variant="h6">{t('no_noti_msg')}</Typography>
    </div>}
    </Paper>
  )
}

export default Notifications