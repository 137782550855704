import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import getFromApi from "../../api/get";
import ListSkeleton from '../../components/ListSkeleton'
import distinct from "../../helper/distinct";
import { useTranslation } from "react-i18next";

const PaymentScreen = () => {
  const {t} = useTranslation()
  const [payments, setPayments] = useState([])
  const user = JSON.parse(sessionStorage.getItem('user'))
  const [courseInfo, setCourseInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getFromApi('/api/student/order', {token: user.token})
    .then(res => {
      if(res === undefined) {
        setIsLoading(false)
        return
      }
      setPayments(res.studentCourseOrders)})
    .catch(err => console.log(err))
  },[])

  useEffect(() => {
    if(payments.length < 1)
      return
    let course = payments.map(p => p.courseId)
    course = course.filter(distinct)
    getFromApi('/api/student/course/info',{token: user.token, courseIds: course.toString()})
    .then(r => {
      if(r !== undefined) {
        setCourseInfo(r.courses.map(c => {
          return {id: c.id, title: c.title}
        }))
      }
      setIsLoading(false)
    })
    .catch(err => console.log(err))

  },[payments])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4">{t('payments')}</Typography>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? <ListSkeleton num={3} width={"80%"} /> : 
        payments.length > 0 && courseInfo.length > 0 ? payments.map((rec, index) => {
          return <Paper key={index} className="paperItem">
            <Typography variant="subtitle1">{t('date')} {new Date(rec.createTime).toLocaleDateString()}</Typography>
            {/* <Typography>Ref. No.: {rec.refno}</Typography> */}
            <Typography>{t('course')} {courseInfo.find(i => i.id === rec.courseId).title}</Typography>
            <Typography>{t('amount')}{rec.price}</Typography>
            {/* <Typography>Method: {rec.method}</Typography> */}
          </Paper>
        }) : <div style={{display: 'flex', height: '80vh', alignItems: 'center', justifyContent: 'center'}}>
        <Typography variant="h6">{t('no_payment')}</Typography>
      </div>} 
      </Grid>
    </Grid>
  )
}

export default PaymentScreen