import axios from "axios";

const getFromApi = async (url, params) => {

  try {
    let res = await axios({
      method: 'get',
      url,
      params
    })
    // console.log(res)
    return res.data;
  } catch(error) {
    console.log(error)
  }
}

export default getFromApi;