import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField, FormControl,  Paper, Button, Alert, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import postByApi from  '../api/post';
import i18next from "../i18n";

const LoginScreen = () => {
  const { t } = useTranslation();
  // const [password2Lbl, setPassword2Lbl] = useState('confirm password');
  // const [btnLbl, setBtnLbl] = useState('Sign Up');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // const [password2, setPassword2] = useState('');
  // const [formType, setFormType] = useState('login');
  const [curIsEng, setCurIsEng] = useState(sessionStorage.getItem('lang') === 'en');
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(true)
  const [alertMsg, setAlertMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleLangChange = () => {
    const lang = curIsEng? 'ch' : 'en';
    i18next.changeLanguage(lang);
    setCurIsEng(!curIsEng)
    sessionStorage.setItem('lang', lang)
  }

  const handleSubmit = (e) => {
    //validate input
    e.preventDefault()
    setIsValid(true)
    setIsLoading(true)
    if(username === "" || password === "") {
      setIsValid(false)
      setAlertMsg('Empty username or/and password!')
      setIsLoading(false)
      return
    }
    handleLogin()
  }

  const handleLogin = () => {
    const user = {
      account: username,
      password
    }
    postByApi('/api/student/login', user)
    .then(res => {
      if(res === undefined) {
        setIsValid(false)
        setAlertMsg('Login Failed! Check your username and/or password.')
        return
      }
      sessionStorage.setItem('user', JSON.stringify(res.student))
      console.log('user info', res.student)
    })
    .then(() => {
      setIsLoading(false)
      if(sessionStorage.getItem('user') !== null)
        navigate('/home')
    })
    .catch(err => console.log(err))
  }

  // const handleSignUp = () => {
  //   if(password !== password2)
  //     return
  //   console.log('sign up with', username, password)
  // }

  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  // const handlePassword2Change = (e) => {
  //   setPassword2(e.target.value)
  // }

  // const changeFormType = () => {
  //   if(formType === 'login') {
  //     setFormType('signup')
  //     setBtnLbl('Login')
  //   }
  //   else {
  //     setFormType('login')
  //     setBtnLbl('Sign Up')
  //   }
  // }  

  useEffect(() => {
    i18next.changeLanguage(sessionStorage.getItem('lang'))
  },[])

  return (
    <div className="loginContainer">
      <Paper elevation={3} sx={{ width: {xs: '70vw', md: '30vw'}, padding: '5vh 5vw', display: 'flex', flexDirection: 'column'}}>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <img src="/student/ossd_icon.jpg" alt="" style={{height: '7vh'}}/></div>
        <Button sx={{alignSelf: 'center'}} onClick={() => handleLangChange()}>{t('changeLang')}</Button>
        {!isValid && <Alert severity="warning" sx={{marginTop: '1vh', justifyContent: 'center'}}>{alertMsg}</Alert>}
        <form className="loginForm" onSubmit={handleSubmit} style={{width: '100%'}}>
          <FormControl className="control">
            <TextField 
              label={t('ac')} 
              variant="standard"
              value={username}
              onChange={handleUsernameChange}
            /> 
          </FormControl>
          <FormControl required>
            <TextField 
              label={t('pw')}
              variant="standard"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </FormControl>
          {/* {formType === 'signup' ? 
          <FormControl>
            <TextField
              label={password2Lbl}
              variant="standard"
              type="password"
              value={password2}
              onChange={handlePassword2Change}
              />
          </FormControl> : <></>} */}
          <Button type="submit">{isLoading ? <><CircularProgress size={20} sx={{marginRight: '2vh'}} />{t('load')}</> : t('login')}</Button>        
        </form>
        {/* <div>
          {formType === 'login' ? 'do not have an account?': 'have an account?'}
          <Button onClick={changeFormType}>{btnLbl}</Button>
        </div>  */}
      </Paper>      
    </div>
  )
}

export default LoginScreen;