import React from "react";
import { Drawer, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

const SettingsDrawer = (props) => {
  const {t} = useTranslation()
  const settings = ['profile','settings', 'course_history','payments', 
  //'contact_us'
]
  const curScreen = props.current

  return (
    <Drawer variant="permanent" sx={{
      display: { md: 'block', xs: 'none' },
      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '15%', paddingTop: '12vh' },
    }}>
      {settings.map(setting => {
        return (
          <List key={setting}>
            <ListItem>
              <ListItemButton 
                onClick={() => props.handleChange(setting)}
                selected={setting === curScreen}
              >
                <ListItemText 
                  primary={t(setting)}
                  primaryTypographyProps={{style: { color: '#fff', fontWeight: setting.screen === curScreen ? '600' : 'inherit',}, component: 'div' }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        )
      })}
    </Drawer>
  )
}

export default SettingsDrawer