import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import SettingsDrawer from "../../components/SettingsDrawer";
import ContactUsScreen from "./ContactUsScreen";
import CourseHistoryScreen from "./CourseHistoryScreen";
import PaymentScreen from "./PaymentScreen"
import ProfileScreen from "./ProfileScreen"
import SettingsScreen from "./SettingsScreen"
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";

const PersonalCenterScreen = () => {
  const [showScreen, setShowScreen] = useState({})
  const location = useLocation()

  const screens = [
    {
      value: 'profile',
      component: <ProfileScreen />,
    },
    {
      value: 'settings',
      component: <SettingsScreen />,
    },
    {
      value: 'course_history',
      component: <CourseHistoryScreen />,
    },
    {
      value: 'payments',
      component: <PaymentScreen />,
    },
    // {
    //   value: 'contact_us',
    //   component: <ContactUsScreen/>
    // }
  ]

  const changeScreen = (screen) => {
    const target = screens.find(s => s.value === screen)
    setShowScreen(target)
    // location.state.subroute = screen
    console.log(target)
  }

  useEffect(() => {
    if(location.state)
      changeScreen(location.state.subroute)
    else
      changeScreen('profile')
  }, [location])

  return (
    <div>
      <Header/>
      <div className="container">
        {showScreen && <Grid container>
          <Grid item xs={0} md={1.8}>
            <SettingsDrawer current={showScreen.value} handleChange={changeScreen}/>
          </Grid>
          <Grid item xs={12} md={10.2}>
            {showScreen.component}
          </Grid>
        </Grid>}
      </div>
    </div>
  )
}

export default PersonalCenterScreen