import React, { useEffect, useState } from "react";
import Header from '../../components/Header';
import { 
  Box,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemButton, 
  ListItemText, 
  Paper, 
  Button, 
  Rating, 
  Tabs, 
  Tab, 
  Typography,
  FormControl,
  TextField,
  Snackbar,
  Alert,
  CircularProgress} from "@mui/material";
import getFromApi from '../../api/get'
import postByApi from '../../api/post'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import ListSkeleton from "../../components/ListSkeleton";
import distinct from "../../helper/distinct";
import { useTranslation } from "react-i18next";

const UpcomingBlock = ({user}) => {
  const {t} = useTranslation()
  const [upcomingList, setUpcomingList] = useState([])
  const [names, setNames] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getFromApi('/api/student/class_list',{
      token: user.token,
      limit: 3,
      type: 1
    })
    .then(r => {
      if(r === undefined) {
        setIsLoading(false)
        return
      }  
      setUpcomingList(r.classes)
    })
    .catch(err => console.log(err))
  },[])

  useEffect(() => {
    const arr = []
    if(upcomingList.length < 1)
      return
    let courses = upcomingList.map(u => u.courseId)
    courses = courses.filter(distinct)
    getFromApi('/api/student/course/info',
    {
      token: user.token,
      courseIds: courses.toString()
    })
    .then(r => {
      if(r === undefined) {
        setIsLoading(false)
        return
      }
      upcomingList.forEach(u => {
        const course = r.courses.find(c => c.id === u.courseId)
        let chapter, lesson, courseName = '', chapterName = ''
        if(course !== undefined) {
          courseName = course.title
          chapter = course.chapters.find(ch => ch.id === u.chapterId)
        }
        if(chapter !== undefined) {
          chapterName = chapter.title
          lesson = chapter.lessons.find(l => l.id === u.lessonId)
        }
        const lessonName = lesson === undefined ? '' : lesson.title
        arr.push({
          courseName, chapterName, lessonName
      })
      })
      setNames(arr)
      setIsLoading(false)
    })
    .catch(err => console.log(err))
  },[upcomingList])

  const navigateToCourseDetails = () => {
    //TODO
  }

  const startClass = () => {
    //TODO
  }

  const reschedule = () => {
    //TODO
  }

  const cancelClass = () => {
    //TODO
  }

  return (
    <div>
      {isLoading ? <ListSkeleton num={3} width={"90%"} height={"10vh"} /> :
      names.length > 0 ? upcomingList.map((u,index) => {
        return <Paper className="paperItem" key={index} style={{margin: '2vh 0'}}>
          <Grid container>
          <Grid item xs={12} md={9}>
            {names.length > 0 ? 
              <Typography variant="h6">{names[index].courseName} {names[index].chapterName} {names[index].lessonName}</Typography> : 
              <Typography variant="h6">{u.courseId} {u.chapterId} {u.lessonId}</Typography>
            }
            <p style={{display: 'flex', alignItems: 'center'}}><SupportAgentOutlinedIcon sx={{paddingRight: '0.5vw'}} />{u.teacher.alias}</p>
            <p style={{display: 'flex', alignItems: 'center'}}><AccessTimeIcon sx={{paddingRight: '0.5vw'}} />{new Date(u.startTime).toLocaleString()}</p>
            <Button onClick={navigateToCourseDetails} variant="contained">{t('class_materials')}</Button>
          </Grid>
          <Grid item xs={12} md={3} sx={{display: 'flex', flexDirection: {xs: 'row', md:'column'}}}>
            <Button onClick={startClass}>{t('start')}</Button>
            {/* <Button onClick={reschedule}>Reschedule</Button>
            <Button onClick={cancelClass}>Cancel</Button> */}
          </Grid>
          </Grid>
        </Paper>
      }):<div style={{display: 'flex', height: '80vh', alignItems: 'center', justifyContent: 'center'}}>
      <Typography variant="h6">{t('no_upcoming_msg')}</Typography>
    </div>}
    </div>  
  )
} 

const PastBlock = ({user}) => {
  const {t} = useTranslation()
  const [pastList, setPastList] = useState([])
  const [names, setNames] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [judges, setJudges] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [isError, setIsError] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [editIdx, setEditIdx] = useState(-1)

  const getPastList = () => {
    setIsLoading(true)
    getFromApi('/api/student/class_list',{
      token: user.token,
      limit: 3,
      type: 3
    })
    .then(r => {
      if(r === undefined) {
        setIsLoading(false)
        return
      }
      setPastList(r.classes)
    })
    .catch(err => console.log(err))
  }

  useEffect(() => {
    getPastList()    
  },[])

  useEffect(() => {
    const arr = []
    if(pastList.length < 1)
      return
    let courses = pastList.map(p => p.courseId)
    courses = courses.filter(distinct)
    getFromApi('/api/student/course/info',
    {
      token: user.token,
      courseIds: courses.toString()
    })
    .then(r => {
      if(r === undefined) {
        setIsLoading(false)
        return
      }
      pastList.forEach(u => {
        const course = r.courses.find(c => c.id === u.courseId)
        let chapter, lesson, courseName = '', chapterName = ''
        if(course !== undefined) {
          courseName = course.title
          chapter = course.chapters.find(ch => ch.id === u.chapterId)
        }
        if(chapter !== undefined) {
          chapterName = chapter.title
          lesson = chapter.lessons.find(l => l.id === u.lessonId)
        }
        const lessonName = lesson === undefined ? '' : lesson.title
        arr.push({
          courseName, chapterName, lessonName
        })
      })
      setNames(arr)
      setIsLoading(false)
    })
    .catch(err => console.log(err))

    const temp = []
    pastList.forEach(c => {
      if(c.studentJudges === undefined || c.studentJudges.length < 1) 
        temp.push({comment: '', score: 0})
      else {
        const j = c.studentJudges.find(j => j.studentId === user.id)
        if(j !== undefined)
          temp.push({comment: j.studentComment, score: j.studentScore})
        else
          temp.push({comment: '', score: 0})
      }
    }) 
    setJudges(temp)
  }, [pastList])

  const navigateToCourseDetails = () => {
    //TODO
  }
  
  const updateJudge = (idx, comment, score) => {
    const arr = [...judges]
    arr[idx] = {comment, score}
    setJudges(arr)
  }

  const submitFeedback = (index) => {
    setEditIdx(index)
    setSubmitting(true)
    setIsError(false)
    const data = {
      token: user.token,
      studentClassJudges: [{
        studentClassInformationId: pastList[index].id,
        studentComment: judges[index].comment,
        studentScore: judges[index].score
      }],
    }
    postByApi('/api/student/judge', data)
    .then(r => {
      console.log('post judege res', r)
      if(r === undefined)
        setIsError(true)
      else
        getPastList()
      setSubmitting(false)
      setEditIdx(-1)
      setOpenSnackbar(true)
    })
    .catch(err => console.log(err))
  }

  return (
    <div>
      {isLoading ? <ListSkeleton num={3} width={'90%'} height={'10vh'} /> : 
      names.length > 0 ? pastList.map((past, index) => {
        return <div key={index}>
          <Grid container>
          <Grid item xs={12} md={6}>
            <Paper className="paperItem" style={{margin: '2vh 0'}}>
            {names.length > 0 ? 
              <Typography variant="h6">{names[index].courseName} {names[index].chapterName} {names[index].lessonName}</Typography> : 
              <Typography variant="h6">{past.courseId} {past.chapterId} {past.lessonId}</Typography>
            }
            <p style={{display:'flex', alignItems: 'center'}}><AccessTimeIcon sx={{paddingRight: '0.5vw'}}/>{new Date(past.startTime).toLocaleString()}</p>
            <p style={{display:'flex', alignItems: 'center'}}><SupportAgentOutlinedIcon sx={{paddingRight: '0.5vw'}}/>{past.teacher.alias}</p>
            <p style={{display:'flex', alignItems: 'center'}}><ChatOutlinedIcon sx={{paddingRight: '0.5vw'}} />{past.comment}</p>
            <Button variant="contained" onClick={navigateToCourseDetails}>{t('class_materials')}</Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            {judges.length > 0 && <Paper elevation={0} sx={{margin: '2vh 1vw',justifyContent: 'space-between', minWidth: '30vw'}} className="paperItem" >
              <form style={{width: '100%'}}>
                <Typography variant="h6">{t('s_comment')}</Typography>
                <FormControl sx={{width: '100%'}}>
                  <TextField
                    multiline
                    rows={5}
                    size="small"
                    value={judges[index].comment} 
                    InputProps={{readOnly: past.studentJudges !== undefined}}
                    onChange={(e) => {updateJudge(index, e.target.value, judges[index].score)}}
                  />
                </FormControl>
                <Typography variant="h6">{t('s_rating')}</Typography>
                <FormControl>
                  <Rating 
                    value={judges[index].score} 
                    readOnly={past.studentJudges !== undefined}
                    onChange={(e, newValue) => {updateJudge(index, judges[index].comment, newValue)}} />
                </FormControl>
              </form>
              {past.studentJudges === undefined && <Button onClick={() => submitFeedback(index)}>{submitting && editIdx === index ? <><CircularProgress size={20} sx={{marginRight: '2vh'}} />{t('load')}</> : t('submit')}</Button>}
            </Paper>}
          </Grid>
          </Grid>
        </div>
      }): <div style={{display: 'flex', height: '80vh', alignItems: 'center', justifyContent: 'center'}}>
      <Typography variant="h6">{t('no_class_rec_msg')}</Typography>
    </div>}
    <Snackbar 
        open={openSnackbar} 
        autoHideDuration={1500} 
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        sx={{marginTop: '5vh'}}  
      >
        <Alert severity={isError? "warning" : "success"} sx={{width: '100%',alignItems: 'center'}}>
          {isError? t('feedback_post_failed') : ('feedback_post_success')}
        </Alert>
      </Snackbar>
    </div>
  )
}

const MyClassScreen = () => {
  var drawerList = ['upcoming', 'past']
  const {t} = useTranslation()
  const [isUpcoming, setIsUpcoming] = useState(true)
  const user = JSON.parse(sessionStorage.getItem('user'))


  const changeList = (idx) => {
    // eslint-disable-next-line default-case
    switch(idx) {
      case 0:
        setIsUpcoming(true);break;
      case 1:
        setIsUpcoming(false); break;
    }
  }

  useEffect(() => {

  },[isUpcoming])

  return (
    <div>
      <Header />
      <div className="container">
        <Grid container>
          <Grid item xs={0} md={1.8} >
            <Drawer variant="permanent" sx={{
            display: { md: 'block', xs: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '15%' },
          }}>
              <List style={{paddingTop: '12vh'}}>
                {drawerList.map((text, index) => {
                  return <ListItem key={text}>
                    <ListItemButton onClick={() => changeList(index)} selected={!isUpcoming == index}>
                      <ListItemText 
                        primary={t(text)}
                        primaryTypographyProps={{style: { color: '#fff', fontWeight: !isUpcoming == index ? '600' : 'inherit',}, component: 'div' }} 
                      />
                    </ListItemButton>
                  </ListItem>
                })}
              </List>
            </Drawer>
          </Grid>
          <Grid item xs={12} sx={{display: {xs: 'flex', md: 'none'}}}>
          <Tabs
            value={isUpcoming? 0 : 1}
            variant="scrollable"
            scrollButtons="auto"
          >
            {drawerList.map((text, index) => {
              return <Tab key={index} label={text} onClick={() => changeList(index)} />
            })}
          </Tabs>
        </Grid>
          <Grid item xs={12} md={10.2}>
            {isUpcoming? <UpcomingBlock user={user}/> : <PastBlock user={user}/>}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default MyClassScreen;