import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import { Grid, Paper, Typography, Button, LinearProgress } from "@mui/material";
import Notifications from "../components/Notifications";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import { theme } from "../theme";
import getFromApi from "../api/get";
import distinct from "../helper/distinct";
import ListSkeleton from "../components/ListSkeleton";
import { useTranslation } from "react-i18next";

const HomeScreen = () => {
  const { t } = useTranslation()
  const [user, setUser] = useState({})
  const [upcoming, setUpcoming] = useState([])
  const [progress, setProgress] = useState([])
  const [coursesDetail, setCoursesDetail] = useState([])
  const [names, setNames] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    const u = JSON.parse(sessionStorage.getItem('user'))
    setUser(u)
  },[])

  const getCourseProgress = () => {
    getFromApi('/api/student/my_course', {token: user.token})
    .then(r => {
      if(r === undefined)
        return
      setProgress(r.courses.filter(c => c.completedLessonPercentage > 0))
      })
    .catch(err => console.log(err))
  }

  const getUpcomingClass = () => {
    getFromApi('/api/student/class_list',{
      token: user.token,
      limit: 3,
      type: 1
    })
    .then(r => {
      if(r === undefined) {
        setIsLoading(false)
        return
      }
      setUpcoming(r.classes)
      let courses = r.classes.map(c => c.courseId)
      courses = courses.filter(distinct)
      getFromApi('/api/student/course/info', {
        token: user.token,
        courseIds: courses.toString()
      })
      .then(r => setCoursesDetail(r.courses))
      .catch(err => console.log(err))
    })
    .catch(err => console.log(err))
    
  }

  const getCourseNames = () => {
    const arr = []
    if(upcoming.length < 1)
      return
    upcoming.forEach(u => {
      const course = coursesDetail.find(c => c.id === u.courseId)
      let chapter, lesson, courseName = '', chapterName = ''
      if(course !== undefined) {
        courseName = course.title
        chapter = course.chapters.find(ch => ch.id === u.chapterId)
      }
      if(chapter !== undefined) {
        chapterName = chapter.title
        lesson = chapter.lessons.find(l => l.id === u.lessonId)
      }
      const lessonName = lesson === undefined ? '' : lesson.title
      arr.push({
        courseName, chapterName, lessonName
      })
    })
    setNames(arr)
    setIsLoading(false)
    console.log('names', arr)
  }

  useEffect(() => {
    if(Object.keys(user).length === 0 || user.token == null)
      return
    
    getCourseProgress()
    getUpcomingClass()
    
  },[user])

  useEffect(() => {
    getCourseNames()
  }, [coursesDetail])

  return (
    <div style={{backgroundColor: '#fff'}}>
      <Header />
      <div className="container">
        {user && <Grid container spacing={3} sx={{padding: '0 5vw'}}>
          <Grid item xs={12}>
            <Typography variant="h4">{t('greeting')} {user.alias},</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
              <Typography variant="h5">{t('upcoming_class')}</Typography>
              {isLoading ? <ListSkeleton num={3} width="60%" /> :
                upcoming.length > 0 ? upcoming.map((uClass, index) => {
                  return <Paper key={index} elevation={3} className="paperItem" sx={{width: {md: '60%'}}}>
                    {names.length > 0 ? 
                      <Typography variant="h6">{names[index].courseName} {names[index].chapterName} {names[index].lessonName}</Typography> : 
                      <Typography variant="h6">{uClass.courseId} {uClass.chapterId} {uClass.lessonId}</Typography>
                    }
                    <Typography sx={{display: 'flex', alignItems: 'center', paddingTop: '1vh'}}><SupportAgentOutlinedIcon sx={{paddingRight: '0.5vw'}}/> {uClass.teacher.alias}</Typography>
                    <Typography sx={{ display: 'flex', alignItems: 'center', paddingTop: '2vh', color: theme.palette.secondary.main}}><AccessTimeIcon sx={{paddingRight: '0.5vw'}}/>{new Date(uClass.startTime).toLocaleString()}</Typography>
                    <Button variant="contained" style={{display: 'flex', alignSelf: "flex-end"}}>{t('start')}</Button>
                  </Paper>
                }) : <Paper className="paperItem" sx={{width: {md: '60%'}}}>
                  <Typography variant="h6">{t('no_upcoming_msg')}</Typography>
                </Paper>
              }
          </Grid>
          <Grid item xs={0} md={3} sx={{display: {xs: 'none', md: 'flex'}}}>
            <Notifications />
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant="h5">{t('progress')}</Typography>
            {isLoading ? <ListSkeleton num={2} width="100%" height="3vh" /> : progress.length > 0 ? progress.map((p, index) => {
              return <div key={index}>
                <Typography variant="body1" sx={{paddingTop: '2vh'}}>{p.title}</Typography>
                <LinearProgress variant="determinate" value={p.completedLessonPercentage*100} />
                <Typography>{p.completedLessonPercentage*100}%</Typography>
              </div>
            }) : <Paper className="paperItem" sx={{width: {md: '60%'}}}>
                  <Typography variant="h6">{t('no_progress_msg')}</Typography>
                </Paper>
            }
          </Grid>
        </Grid>}
      </div>
    </div>
  )
}

export default HomeScreen;