import React, { useEffect, useState } from "react";
import { Grid, Typography, Avatar, FormControl, TextField, Button, CircularProgress, Snackbar, Alert} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import getFromApi from '../../api/get'
import postByApi from '../../api/post'
import { useTranslation } from "react-i18next";

const ProfileScreen = () => {
  const user = JSON.parse(sessionStorage.getItem('user'))
  const {t} = useTranslation()
  const [mobile, setMobile] = useState(user.mobile)
  const [alias, setAlias] = useState(user.alias)
  const [email, setEmail] = useState(user.email)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)

  const saveInfo = (e) => {
    
    e.preventDefault()
    setIsLoading(true)
    const data = {
      token: user.token,
      student: {
        id: user.id,
        mobile,
        alias,
        email,
        messageConfig: {
          enableScheduleMessage: true,
          scheduleMessageTime: 0,
          enableCancellMessage: true,
          enableMobilePush: true,
          enableEmailMessage: true,
        }
      }
    }

   console.log('new info:', data)
   
   postByApi('/api/student/info/modify',data)
   .then(r => {
      setIsError(r === undefined)
      if(r !== undefined)
        getFromApi('/api/student/info', {token: user.token})
        .then(r => {
          sessionStorage.setItem('user', JSON.stringify({...r.student, token: user.token}))
          setOpenSnackbar(true)
          setIsLoading(false)
        })
        .catch(err => console.log(err))
      else {
        setOpenSnackbar(true)
        setIsLoading(false)
      }
    })
   .catch(err => console.log(err))
  }

  return (
    user && <div style={{paddingTop: '5vh'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} sx={{display: 'flex', justifyContent: 'center'}}>
          <Avatar sx={{width: '15vh', height: '15vh'}} src={user.protraitUrl}>
            {!user.protraitUrl && <AccountCircleIcon style={{width: '100%', height: '100%'}}/>}
          </Avatar>
        </Grid>
        <Grid item xs={12} md={10} sx={{display: 'flex', justifyContent: {xs: 'center', md: 'flex-start'}}}>
          <Typography variant="h4">{user.lastName}, {user.firstName}</Typography>
        </Grid>
      </Grid>
      <form className="inputForm" onSubmit={saveInfo}>
        <FormControl className="control" size="small">
          <Typography>{t('alias')}</Typography>
          <TextField value={alias} variant="standard" onChange={(e) => setAlias(e.target.value)}/>
        </FormControl>
        <FormControl className="control" size="small">
          <Typography>{t('ac')}</Typography>
          <TextField value={mobile} variant="standard" onChange={e => setMobile(e.target.value)}/> 
        </FormControl>
        <FormControl className="control" size="small">
          <Typography>{t('email')}</Typography>
          <TextField value={email} variant="standard" onChange={e => setEmail(e.target.value)} />
        </FormControl>
        {/* <FormControl className="control" size="small">
          <Typography>Time Zone</Typography>
          <TimezoneSelect 
            value={selectedTimezone} 
            onChange={setSelectedTimezone} 
            timezones={allTimezones} />
        </FormControl> */}
        <Button type="submit">{isLoading ? <><CircularProgress size={20} sx={{marginRight: '2vh'}} />{t('load')}</> : t('submit')}</Button>
      </form>
      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={2000} 
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        sx={{marginTop: '5vh'}}  
      >
        <Alert severity={isError? "warning" : "success"} sx={{width: '100%',alignItems: 'center'}}>
          {isError? t('info_post_failed') : t('info_post_success')}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default ProfileScreen;