import {Routes, Route, Navigate, HashRouter} from 'react-router-dom';
import './App.css';
import LoginScreen from './screens/LoginScreen';
import HomeScreen from './screens/HomeScreen';
import MyClassScreen from './screens/class/MyClassScreen'
import MyCourseScreen from './screens/course/MyCourseScreen'
import CourseHistoryScreen from './screens/personal_center/CourseHistoryScreen'
import PaymentScreen from './screens/personal_center/PaymentScreen'
import SettingsScreen from './screens/personal_center/SettingsScreen';
import ProfileScreen from './screens/personal_center/ProfileScreen'
import ContactUsScreen from './screens/personal_center/ContactUsScreen'
import PersonalCenterScreen from './screens/personal_center/PersonalCenterScreen';

function App() {
  sessionStorage.setItem('lang', 'en')
  
  return (
    <HashRouter window={window}>
      <Routes>
        <Route path='/' element={<Navigate to='/login' />} />
        <Route path='/login' element={<LoginScreen/>}/>
        <Route path='/home' element={<HomeScreen/>} />
        <Route path='/myclass' element={<MyClassScreen/>} />
        <Route path='/mycourse' element={<MyCourseScreen/>} />
        <Route path='/personal_center' element={<PersonalCenterScreen/>}>
        {/* <Route path='course_history' element={<CourseHistoryScreen/>} />
        <Route path='payments' element={<PaymentScreen/>} />
        <Route path='settings' element={<SettingsScreen/>} />
        <Route path='profile' element={<ProfileScreen/>} />
        <Route path='contact_us' element={<ContactUsScreen/>} /> */}
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
