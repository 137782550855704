import { createTheme } from "@mui/material";


export const theme = createTheme({
  palette: {
    primary: {
      main: '#a0211e'
    },
    secondary: {
      main: '#2d2424'
    }
  },
  typography: {
    h4: {
      fontWeight: 900,
    },
    h5: {
      color: "#2d2424",
      fontWeight: 600,
    },
    h6: {
      color: '#a0211c',
      fontWeight: 600,
    },
    subtitle1:{
      fontWeight: 600,
    },
    body2: {
      color: '#6d6d6d',
      lineHeight: '2rem',
      textAlign: 'justify'
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '1rem'
        },
      },
      defaultProps: {
        elevation: 4
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#a0211c',
            color: '#fff',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#a0211c'
          },
          '&.MuiListItemButton-root:hover': {
            backgroundColor: '#a0211c',
          }
        },
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorRight: {
          width: '100vw',
          paddingTop: '8vh'
        },
        paperAnchorLeft: {
          backgroundColor: '#2d2424',
        }
      }
    },
  }
})
