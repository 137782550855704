import React, { useEffect, useState } from "react";
import { AppBar, Box, Toolbar, Button, IconButton, Tooltip, Menu, MenuItem, Typography, Drawer, ListItem, List, ListItemButton, ListItemText, Divider, Dialog, useTheme, DialogTitle, DialogActions } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import DehazeOutlinedIcon from '@mui/icons-material/DehazeOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import LanguageIcon from '@mui/icons-material/Language';
import Notifications from "./Notifications";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Header = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const curPath = useLocation().pathname
  const theme = useTheme()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openNoti, setOpenNoti] = useState(false)
  const [logoutConfirm, setLogoutConfirm] = useState(false)
  const [anchorElUser, setAnchorElUser] = useState(null);
  const user = JSON.parse(sessionStorage.getItem('user'))

  const settings = ['profile','settings','course_history','payments',
    // 'contact_us'
  ]
  const pages = ['home','mycourse','myclass'];

  const handleNavigation = (k) => {
    navigate(`/${k}`);
  }

  const handleSettingNavigation = (k) => {
    navigate('/personal_center', {state: {subroute: k}})
  }

  const handleLangChange = () => {
    const lang = sessionStorage.getItem('lang') === 'en' ? 'ch' : 'en'
    i18next.changeLanguage(lang)
    sessionStorage.setItem('lang', lang)
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const toggleNoti = () => {
    setOpenNoti(!openNoti)
  }

  const toggleLogoutConfirm = () => {
    setLogoutConfirm(!logoutConfirm)
  }

  const handleResize = () => {
    if(window.innerWidth >= 960)
      setOpenDrawer(false)
  }

  const handleLogout = () => {
    //TODO
    sessionStorage.removeItem('user')
    navigate('/login')
  }

  useEffect(() => {
    // let u = sessionStorage.getItem('user')
    if(Object.keys(user).length < 1)
      navigate('/login')
    // u = JSON.parse(u)
    // setUser(u)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  },[])

  return (
    <AppBar position="fixed" style={{backgroundColor: "white", padding: "0 5vw", zIndex: 1201 }}>
      <Toolbar disableGutters>
        <img
          src="/student/ossd_icon.jpg"
          alt=""
          style={{
            height: '5vh',
            objectFit: 'contain',
          }}
        />
        <Box sx={{ flexGrow: 1, paddingLeft: '2rem', display: {xs: 'none', md: 'flex'}}}>
          {pages.map((page => (
            <Button 
            key={page} 
            onClick={() => handleNavigation(page)} 
            sx={{ my: 2, color: curPath === ('/'+page) ? theme.palette.primary.main : 'black', fontWeight: curPath === ('/'+page) ? 600: 'normal', display: 'block'}}>
              {t(page)}
            </Button>
          )))}
        </Box>
        <Box sx={{ flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>
            <Button onClick={handleLangChange}>
              <LanguageIcon />
              <Typography sx={{padding: '0 0.5vw'}}>{t('changeLang')}</Typography>
            </Button>
            <Tooltip title="Open settings">
              <Button onClick={handleOpenUserMenu}>{`${user.firstName} ${user.lastName}`}</Button>
            </Tooltip>
            <IconButton onClick={toggleLogoutConfirm}><LogoutIcon/></IconButton>
            <Menu
              sx={{ mt: '5vh' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleSettingNavigation(setting)}>
                  <Typography textAlign="center">{t(setting)}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box 
            sx={{display: {xs: 'flex', md: 'none'}, justifyContent: 'flex-end', flexGrow: 1}}
          >
            <IconButton onClick={toggleNoti}><NotificationsIcon /></IconButton>
            <IconButton onClick={toggleDrawer}><DehazeOutlinedIcon /></IconButton>
          </Box>
      </Toolbar>
      <Drawer
        open={openDrawer}
        onClose={toggleDrawer}
        anchor="right"
      >
        <List>
        {pages.map(page => {
          return <ListItem key={page}>
            <ListItemButton onClick={() => {
              toggleDrawer();
              handleNavigation(page)
            }}>
              <ListItemText primary={t(page)} />
            </ListItemButton>
          </ListItem>
        })}
        </List>
        <Divider/>
        <List>
          {settings.map(setting => {
            return <ListItem key={setting}>
              <ListItemButton onClick={() => {
                toggleDrawer();
                handleSettingNavigation(setting)
              }}>
                <ListItemText primary={t(setting)} />
              </ListItemButton>
            </ListItem>
          })}
        </List>
        <Divider />
        <ListItem>
          <ListItemButton onClick={toggleLogoutConfirm}>
            <ListItemText primary="LOGOUT"/>
          </ListItemButton>
        </ListItem>
      </Drawer>
      <Dialog
        open={openNoti}
        onClose={toggleNoti}
      >
        <div style={{padding: '5vh 5vw', flexWrap: 'nowrap', display: 'flex'}}>
          <Notifications />
        </div>
      </Dialog>
      <Dialog
        open={logoutConfirm}
        sx={{padding: '5vw'}}
      >
        <DialogTitle>{t('logout_confirm')}</DialogTitle>
        <DialogActions>
          <Button onClick={toggleLogoutConfirm}>{t('cancel')}</Button>
          <Button onClick={handleLogout}>{t('confirm')}</Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  )
}

export default Header;