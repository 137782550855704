import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { 
  Drawer, 
  List, 
  ListItem, 
  Grid, 
  ListItemButton, 
  ListItemText, 
  Typography, 
  Paper, 
  Stepper,
  Step, 
  StepLabel,
  Tab,
  Tabs,
  Box,
  useTheme,
  StepContent,
  Skeleton} from "@mui/material";
import getFromApi from "../../api/get";
import { useTranslation } from "react-i18next";
import ListSkeleton from "../../components/ListSkeleton";

const CourseProgress = ({course, user}) => {
  const {t} = useTranslation()
  const [courseItem, setCourseItem] = useState({})
  const [chapters, setChapters] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getCourseDetails = () => {
    setIsLoading(true)
    getFromApi('/api/student/course/info', {token: user.token, courseIds: course.id})
    .then(r => {
      if(r === undefined)
        setChapters([])
      else
        setChapters(r.courses[0].chapters)
      setIsLoading(false)
    })
    .catch(err => console.log(err))
  }

  useEffect(() => {
    setCourseItem(course)
    getCourseDetails()
  },[course])

  return (
    courseItem ? <div>
      <Typography variant="h4">{courseItem.title}</Typography>
      <Typography>{t('course_len')}{t('course_len_detail', {count: courseItem.lessonCount, min: courseItem.classMinutes})}</Typography>
      <Typography variant="body2" sx={{padding: '1vh 2vw'}}>{courseItem.info}</Typography>
      {isLoading ? <ListSkeleton num={5} /> : chapters.length > 0 && chapters.map(chpt => {
        return (
          <div style={{margin: '2vh 0',display: 'flex',flexDirection: 'column', alignItems: 'center'}} key={chpt.id}>
            <Typography variant="h5" align="center">{chpt.title}</Typography>
            <Paper elevation={0} sx={{padding: '2vh 4vw', width: {xs: '80%',md: '60%'}}}>
              <Stepper orientation="vertical" activeStep={-1}>
                {chpt.lessons.map((lesson, index) => {
                  return <Step key={index} expanded completed={courseItem.completedLessonIds?.includes(lesson.id)}>
                    <StepLabel>{lesson.title}</StepLabel>
                    <StepContent sx={{color: courseItem.completedLessonIds?.includes(lesson.id)? 'black' :'grey'}}>
                      <Typography variant="subtitle2">{t('word_list')}</Typography>
                      {lesson.wordList.map((w, index) =>  index === lesson.wordList.length-1?  w : `${w}, `)}
                    </StepContent>
                  </Step>
                })}
              </Stepper>
            </Paper>
          </div>
        )
      })}
    </div>: <></>
  )
}

const MyCourseScreen = () => {
  const {t} = useTranslation()
  const [drawerList, setDrawerList] = useState([])
  const [allCourses, setAllCourse] = useState([])
  const [courseCode, setCourseCode] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const user = JSON.parse(sessionStorage.getItem('user'))
  const theme = useTheme()

  const selectedStyles = {
    color: theme.palette.primary.main,
    opacity: 1
  }

  useEffect(() => {
    setIsLoading(true)
    getFromApi('/api/student/my_course', {
      token: user.token
    })
    .then(r => {
      const courseList = []
      const othersList = []
      if(r !== undefined) {
        setAllCourse(r.courses)
        r.courses.forEach((course, index) => {
          if(course.completedLessonPercentage > 0) {
            if(courseList.length < 1)
              setCourseCode(course.id)
            courseList.push({
              lbl: course.title,
              value: course.id 
            })
          }
          else {
            othersList.push({
              lbl: course.title,
              value: course.id,
            })
          }
        })
      }
      
      setDrawerList([
        {key: 'mycourse', child: [...courseList]},
        {key: 'other_course', child: [...othersList]}
      ])
      setIsLoading(false)
    })
    .catch(err => console.log(err))
  },[])

  const changeList = (code) => {
    setCourseCode(code)
  }

  return (
    <div>
      <Header />
      {!isLoading? <div className="container">
      <Grid container>
        <Grid item xs={0} md={1.8}>
          <Drawer variant="permanent" sx={{
            display: { md: 'block', xs: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '15%', paddingTop: '12vh' },
          }}>
            {drawerList.length > 0 && drawerList.map(cate => {
              return <List key={cate.title}>
                <Typography variant="h6" style={{padding: '0 3vh', color: '#fff'}}>{t(cate.key)}</Typography>
                {cate.child.length > 0 ? cate.child.map((item, index) => {
                  return <ListItem key={item.value}>
                    <ListItemButton onClick={() => changeList(item.value)} selected={courseCode === item.value}>
                      <ListItemText 
                        primaryTypographyProps={{style: { fontWeight: courseCode === item.value ? '600' : 'inherit',}, component: 'div', color: '#fff' }} 
                        primary={item.lbl}/>
                    </ListItemButton>
                  </ListItem>
                }): <ListItem>
                  <ListItemButton>
                  <ListItemText 
                    primaryTypographyProps={{component: 'div', color: '#fff' }}
                    primary={t('no_course')}/>
                  </ListItemButton>
                  </ListItem>}
              </List>
            })}
          </Drawer>
        </Grid>
        <Grid item xs={12} sx={{display: {xs: 'flex', md: 'none'}, paddingBottom: '2vh' }}>
          <Tabs
            value={courseCode}
            variant="scrollable"
            scrollButtons="auto"
          >
            {drawerList.length > 0 && drawerList.map(cate => {
              return cate.child.length > 0 ? cate.child.map(item => {
                return <Box key={item.value} value={item.value}>
                  <Tab label={item.lbl} onClick={() => changeList(item.value)} sx={courseCode === item.value ? selectedStyles : {}}  />
                </Box>
              }):<div></div>
            })}
          </Tabs>
        </Grid>
        <Grid item xs={12} md={10.2}>
          {allCourses.length > 0 ? <CourseProgress course={allCourses.find(c => c.id === courseCode)} user={user} /> :
            <div style={{display: 'flex', height: '80vh', alignItems: 'center', justifyContent: 'center'}}>
              <Typography variant="h6">{t('no_course_msg')}</Typography>
            </div>
          }
        </Grid>
      </Grid>
      </div> : <div className="contianer">
        <Grid container sx={{paddingTop: '12vh'}} spacing={5}>
          <Grid item md={1.8}>
            <Skeleton variant="rectangular" animation="wave" width={'80%'} height={'80vh'} />
          </Grid>
          <Grid item md={10.2} >
            <Skeleton variant="rectangular" animation="wave" width={'80%'} height={'50vh'} />
          </Grid>
        </Grid>
        </div>}
    </div>
  )
}

export default MyCourseScreen;